import Axios from 'axios';

import { getBearer } from './axios-settings';

const BASE_URL = process.env.REACT_APP_SERVICES_URL;



export const getUserToken = (code = '') =>
  Axios.post(`${BASE_URL}/oauth/token`, {
    code,
    responseType: 'token',
    grantType:    'authorization_code',
    clientId:     process.env.REACT_APP_CLIENT_ID,
    clientSecret: process.env.REACT_APP_CLIENT_SECRET,
    redirectUri:  process.env.REACT_APP_REDIRECT_URL,
  });

export const callGetUserProfile = () =>
  Axios.get(
    `${BASE_URL}/userprofiles/me`,
    {headers: {Authorization: getBearer()}}
  );

export const callGetUserInfo = () =>
  Axios.get(
    `${BASE_URL}/userinfo`,
    {headers: {Authorization: getBearer()}}
  );

export const callGetSubscriptions = () =>
  Axios.get(
    `${BASE_URL}/subscriptions`,
    {headers: {Authorization: getBearer()}}
  );

export const callGetAutoRenewInvoice = (product='') =>
  Axios.get(
    `${BASE_URL}/subscriptions/autorenew-invoice/${product}`,
    {headers: {Authorization: getBearer()}}
  );

export const callGetHistories = () =>
  Axios.get(
    `${BASE_URL}/subscriptions/history`,
    {
      params:  {includeOrders: 'true'},
      headers: {Authorization: getBearer()}
    }
  );


export const callGetPaymentMethods = () =>
  Axios.get(
    `${BASE_URL}/paymentmethods`,
    {headers: {Authorization: getBearer()}}
  );

export const callUpdateAutoRenew = ({userId = '', product = '', autoRenew = false} = {}) =>
  Axios.post(
    `${BASE_URL}/subscriptions/autorenew`,
    {userId, autoRenew, productLineUriName: product},
    {headers: {Authorization: getBearer()}}
  );

export const callRemindLater = (product) => {
  return Axios.post(
    `${BASE_URL}/subscriptions/remind-later`,
    {
      productLineUriName: product,
      remindLater: true
    },
    { headers: { Authorization: getBearer() } }
  );
};

export const callUpdatePaymentNonce = (nonce = '', extra = {}) =>
  Axios.post(
    `${BASE_URL}/subscriptions/add-payment-method-from-nonce`,
    {
      ...extra,
      paymentMethodNonce: nonce,
      verifyWithNetworkCheck: true
    },
    {headers: {Authorization: getBearer()}}
  );

export const callUpdateRenewalFrequency = (product = '', plan = '') =>
  Axios.post(
    `${BASE_URL}/subscriptions/renewalFrequency`,
    {productLineUriName: product, renewalFrequency: plan},
    {headers: {Authorization: getBearer()}}
  );

export const callGetBillPayTopUp = () =>
  Axios.get(
    `${BASE_URL}/billpresentment/quota/bill-pay/top-up-preference`,
    {headers: {Authorization: getBearer()}}
  );

export const callUpdateBillPayTopUp = (isOn = false) =>
  Axios.put(
    `${BASE_URL}/billpresentment/quota/bill-pay/top-up-preference`,
    {autoTopUp: isOn},
    {headers: {Authorization: getBearer()}}
  );

export const callTopUpHelperInfo = () =>
  Axios.get(
    `${BASE_URL}/billpresentment/quota/bill-pay/top-ups/offers`,
    {headers: {Authorization: getBearer()}}
  );

export const callGetSkuInfo = (sku) => 
  Axios.get(`${process.env.REACT_APP_API_SERVER_URL}/get_sku_info`,
    {params: {sku}}
  );

export const transformSkuInfo = (skuArray, skuWhitelist = []) =>{
  // Transform from array return into object as expected
  // [ {sku: {data}}, {...}] -> {sku: {data}, ...}
  // If the whitelist is used, only add SKUs from that list
  return skuArray.data.reduce((acc, currSku) =>{
    const key = Object.keys(currSku)[0];
    if(skuWhitelist.length == 0 || skuWhitelist.includes(key)){
      acc[key] = currSku[key];
    }
    return acc;
  }, {});
};
  
export const callGetTierPrices = () =>
  Axios.get(`${process.env.REACT_APP_API_SERVER_URL}/get_tier_prices`);

export const callGetChangeTiers = (product) =>
  Axios.get(
    `${BASE_URL}/subscriptions/allowed-tiers`,
    {
      params: {productLineUriName: product},
      headers: {Authorization: getBearer()}
    }
  );

export const callPostChangeTier = (tier, sku = '', actionType) =>  
  Axios.post(
    `${BASE_URL}/subscriptions/manage`,
    {
      productLineUriName: 'billmanager',
      tierUriName: tier,  //volume100,
      sku: sku,           //170123
      actionType,         //[UPGRADE|DOWNGRADE|CREATE]
    },
    {headers: {Authorization: getBearer()}}
  );

export const callGetPromotions = () =>
  Axios.get(
    `${BASE_URL}/promotions`,
    {headers: {Authorization: getBearer()}}
  );

export const callGetFooter = () =>
  Axios.get(`${process.env.REACT_APP_STORE_URL}/get_footer?json=1`);

// export const callQAMSignOut = (token, isCanada) =>
//   Axios.post(
//     `${process.env.REACT_APP_STORE_URL}${isCanada ? '/canada/' : '/'}qam-signout-nr`,
//     {['qam-token']: token}
//   );

export const callQAMSignOut = () =>
  Axios.delete(`${BASE_URL}/oauth/token/me`, {
    data: {},
    headers: { Authorization: getBearer() }
  });

export const callAddSubscription = (form) =>
  Axios.post(
    `${BASE_URL}/subscriptions`,
    form,
    {headers: {Authorization: getBearer()}}
  );

export const callGetChatHours = () => 
  Axios.get(`${process.env.REACT_APP_API_SERVER_URL}/get_care_hours`);

export const callGetFeatureFlags = () =>
  Axios.get(`${process.env.REACT_APP_API_SERVER_URL}/featureflags/My-Account`);

// export const callGetAutoRenewInvoiceOrders = (product='') =>
//   Axios.get(
//     `${BASE_URL}/subscriptions/history-by-product/${product}`,
//     {
//       params:  {includeOrders: 'true'},
//       headers: {Authorization: getBearer()}
//     }
//   );

// export const callGetSubscriptionTiers = () =>
//   Axios.get(
//     `${BASE_URL}/subscriptions/tiers`,
//     {headers: {Authorization: getBearer()}}
// );
