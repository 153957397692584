import Moment from 'moment';
import { isWithin30Days } from '../helpers/time-helpers';

//Simplifi Price Warnings
// const expiredDatePast = expireOn => {
//   const diff = Moment('May 31 2022', 'MMMM DD YYYY').diff(Moment(expireOn), 'days');
//   return diff;
// };

export const getSimpPriceWarning = (sku, expireOn, priceFeatureFlag) => {
  const MONTHLY_WARNING_SKUS = [];
  const MONTHLY_NEW_PRICE = false;
  const IS_APPLICABLE_MONTHLY = MONTHLY_WARNING_SKUS.includes(sku);

  const ANNUAL_WARNING_SKUS = ['170313', '170421', '170298', '170333', '170419', '170296', '170325', '170368'];
  const ANNUAL_NEW_PRICE = '$71.88';
  const IS_APPLICABLE_ANNUAL = ANNUAL_WARNING_SKUS.includes(sku);

  const expireWithin30Days = isWithin30Days(expireOn);  
  const HAS_PRICE_WARNING = ((IS_APPLICABLE_ANNUAL || IS_APPLICABLE_MONTHLY) && priceFeatureFlag && expireWithin30Days);
  const NEW_PRICE = IS_APPLICABLE_ANNUAL ? ANNUAL_NEW_PRICE : MONTHLY_NEW_PRICE;

  return {
    HAS_PRICE_WARNING: HAS_PRICE_WARNING,
    NEW_PRICE: NEW_PRICE
  };
};
//End Simplifi Price Warnings




//Quicken Price Warnings
export const usPriceWarnings = (tierUriName, expireOn) => {
  const expiryDate = Moment(expireOn);
  const oldPrices = {
    'starter': '$41.88',
    'deluxe': '$59.88',
    'premier': '$83.88',
    'deluxe-standard-fp': '$84.99',
    'deluxe-flex-fp': '$94.99',
    'premier-standard-fp': '$109.99',
    'premier-flex-fp': '$119.99',
    'hab-rpm': '$119.88',
    'hab-standard-fp': '$134.99',
    'hab-flex-fp': '$144.99'
  };

  const newPrices = {
    'starter': '$47.88',
    'deluxe': '$71.88',
    'premier': '$95.88',
    'deluxe-standard-fp': '$71.88',
    'deluxe-flex-fp': '$71.88',
    'premier-standard-fp': '$95.88',
    'premier-flex-fp': '$95.88',
    'hab-rpm': '$131.88',
    'hab-standard-fp': '$131.88',
    'hab-flex-fp': '$131.88'
  };

  const dateConstants = {
    WAVE1_START: Moment('2024-01-26', 'YYYY-MM-DD'),
    WAVE1_END: Moment('2024-10-24', 'YYYY-MM-DD'),
    WAVE1_WARN_START: Moment('2023-12-27', 'YYYY-MM-DD'),
    WAVE1_WARN_END: Moment('2024-1-25', 'YYYY-MM-DD'),

    WAVE2_START: Moment('2024-10-25', 'YYYY-MM-DD'),
    WAVE2_END: Moment('2025-01-25', 'YYYY-MM-DD'),
    WAVE2_WARN_START: Moment('2024-01-25', 'YYYY-MM-DD'),
    WAVE2_WARN_END: Moment('2024-04-24', 'YYYY-MM-DD'),
  };

  let WARNING_START = false;
  let WARNING_END = false;
  let WARN_WAVE = '';

  if (expiryDate.isBetween(dateConstants.WAVE1_START, dateConstants.WAVE1_END, undefined, '[]')) {
    WARNING_START = dateConstants.WAVE1_WARN_START;
    WARNING_END = dateConstants.WAVE1_WARN_START;
    WARN_WAVE = 'WAVE1';
  } else if (expiryDate.isBetween(dateConstants.WAVE2_START, dateConstants.WAVE2_END, undefined, '[]')) {
    WARNING_START = dateConstants.WAVE2_WARN_START;
    WARNING_END = dateConstants.WAVE2_WARN_END;
    WARN_WAVE = 'WAVE2';
  } 

  const CUR_DATE = Moment().format('YYYY-MM-DD');
  const WARNING_TIERS = Object.keys(newPrices);
  const IS_WARNING_TIER = WARNING_TIERS.includes(tierUriName);
  const WARNING_ACTIVE = (
    (WARNING_START && WARNING_END) &&
    Moment(CUR_DATE).isBetween(WARNING_START, WARNING_END, undefined, '[]') // date inclusive
  );
  const PRICE_WARNING = (IS_WARNING_TIER && WARNING_ACTIVE);

  return {
    HAS_PRICE_UPDATE: PRICE_WARNING,
    WARN_WAVE: WARN_WAVE,
    WARNING_START: WARNING_START,
    PRICE: (WARN_WAVE == 'WAVE1') ? newPrices[tierUriName] : oldPrices[tierUriName],
    expireOn: expireOn
  };
};

export const caPriceWarnings = (tierUriName, expireOn) => {
  const expiryDate = Moment(expireOn);

  const oldPricesCa = {
    'cash-manager': '$47.88',
    'starter': '$47.88',
    'deluxe': '$71.88',
    'hab': '$107.88',
  };
  
  const newPricesCa = {
    'cash-manager': '$53.88',
    'starter': '$53.88',
    'deluxe': '$83.88',
    'hab': '$119.88',
  };

  const dateConstants = {
    WAVE1_START: Moment('2024-01-26', 'YYYY-MM-DD'),
    WAVE1_END: Moment('2024-10-24'),
    WAVE1_WARN_START: Moment('2023-12-27', 'YYYY-MM-DD'),
    WAVE1_WARN_END: Moment('2024-1-25', 'YYYY-MM-DD'),

    WAVE2_START: Moment('2024-10-25', 'YYYY-MM-DD'),
    WAVE2_END: Moment('2025-01-25', 'YYYY-MM-DD'),
    WAVE2_WARN_START: Moment('2024-01-25', 'YYYY-MM-DD'),
    WAVE2_WARN_END: Moment('2024-04-24', 'YYYY-MM-DD')
  };

  let WARNING_START = false;
  let WARNING_END = false;
  let WARN_WAVE = '';
  if (expiryDate.isBetween(dateConstants.WAVE1_START, dateConstants.WAVE1_END, undefined, '[]')) {
    WARNING_START = dateConstants.WAVE1_WARN_START;
    WARNING_END = dateConstants.WAVE1_WARN_START;
    WARN_WAVE = 'WAVE1';
  } else if (expiryDate.isBetween(dateConstants.WAVE2_START, dateConstants.WAVE2_END, undefined, '[]')) {
    WARNING_START = dateConstants.WAVE2_WARN_START;
    WARNING_END = dateConstants.WAVE2_WARN_END;
    WARN_WAVE = 'WAVE2';
  } 

  const CUR_DATE = Moment().format('YYYY-MM-DD');
  const WARNING_TIERS = Object.keys(newPricesCa);
  const IS_WARNING_TIER = WARNING_TIERS.includes(tierUriName);
  const WARNING_ACTIVE = (
    (WARNING_START && WARNING_END) &&
    Moment(CUR_DATE).isBetween(WARNING_START, WARNING_END, undefined, '[]') // date inclusive
  );
  const PRICE_WARNING = (IS_WARNING_TIER && WARNING_ACTIVE);

  return {
    HAS_PRICE_UPDATE: PRICE_WARNING,
    WARN_WAVE: WARN_WAVE,
    WARNING_START: WARNING_START,
    WARNING_END: WARNING_END,
    PRICE: (WARN_WAVE == 'WAVE1') ? newPricesCa[tierUriName] : oldPricesCa[tierUriName],
    OLD_PRICE: oldPricesCa[tierUriName],
    NEW_PRICE: newPricesCa[tierUriName],
    expireOn: expireOn
  };
};

export const getQknPriceWarn = (tierUriName, expireOn, country) => {
  if(country === 'ca') {
    return caPriceWarnings(tierUriName, expireOn);
  } else {
    return usPriceWarnings(tierUriName, expireOn);
  }
};
//End Quicken Price Warnings

