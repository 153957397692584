import React     from 'react';
import PropTypes from 'prop-types';

import * as S               from './simp-active.module.scss';
import { Legal }            from '../../../common/legal';
import { Price }            from '../../../common/product/price';
import { RemainingTime }    from '../../../common/product/remaining-time';
import { Expiry }           from '../../../common/product/expiry';
import HAPPY_STAR           from '../../../../assets/imgs/art/happy-star.svg';
import MEH_STAR             from '../../../../assets/imgs/art/meh-star.svg';
import { setCtxNestedObj }  from '../../../../context/ctx-actions';
import { useGlobalCtx }     from '../../../../context/ctx-hook';
import { getProductsPaymentMethod } from '../../../../context/selectors/user-selectors';
import { useAutoRenew }     from '../../../../helpers/hooks/use-auto-renew';
import * as StateConstants  from '../../../../helpers/constants/state-constants';
import { getSimpPriceWarning }  from '../../../../helpers/skunotices-helpers';

const PRODUCT = 'acme';




const getAccountMsg = ({active, autoRenew}, hasPayment) => {
  if (!active) return 'Your Simplifi subscription is no longer active';
  else if (!autoRenew) return 'We’re sorry to see you go! You still have access to all benefits until the expiration date below.';
  else if (!hasPayment) return 'In order to not lose your connected services, add a payment method for renewal';
  else return '';
};

const SimpActive = ({
  active,
  sku,
  autoRenew,
  renewalSku,
  renewalFrequency,
  expireOn,
  isCancelled,
  isInTrialPeriod,
  isModal
}) => {
  const {state, globalDispatch} = useGlobalCtx();

  const handleArOn    = useAutoRenew(PRODUCT, true);
  const HAS_PAYMENT   = getProductsPaymentMethod(PRODUCT, state);
  const IS_HAPPY_STAR = active && autoRenew && HAS_PAYMENT;
  const MESSAGE = getAccountMsg({ active, autoRenew }, getProductsPaymentMethod(PRODUCT, state));
  const { HAS_PRICE_WARNING, NEW_PRICE } = getSimpPriceWarning(sku, expireOn, state.featureFlags.q4_2024_simplifi_price_overrides);


  const manageSubscription = React.useCallback(
    async () => globalDispatch(setCtxNestedObj('modal', {
      type:       StateConstants.MODALS.SUBSCRIPTION,
      ctx:        {product: PRODUCT},
      isLoading:  true,
      hasError:   false,
      isDisabled: false
    })),
    [globalDispatch]
  );

  const updatePaymentModal = React.useCallback(
    () => globalDispatch(setCtxNestedObj('modal', {
      type:       StateConstants.MODALS.UPDATE_PAYMENT,
      ctx:        {product: PRODUCT, paymentOnly: false},
      isLoading:  true,
      hasError:   false,
      isDisabled: false
    })),
    [globalDispatch]
  );

  return (
    <>
      {!isModal && MESSAGE && <div className={S.productAlert}>{MESSAGE}</div>}
      {isInTrialPeriod && (
        <>
          <h4 className={S.trialHeading}>Free trial</h4>
          <RemainingTime expireOn={expireOn} className={S.trialRemaining}/>
        </>
      )}

      <Price
        sku={renewalSku}
        renewalFrequency={renewalFrequency}
        className={S.price}
        price={HAS_PRICE_WARNING ? NEW_PRICE : false}
      />

      {!isInTrialPeriod && active && (!autoRenew || !HAS_PAYMENT) && (
        <RemainingTime
          expireOn={expireOn}
          isCancelled={isCancelled}
          className={S.remaining}
        />
      )}
      
      <Expiry
        product="acme"
        className={`${S.productExpiry}`}
      />

      <span className={`${S.dash}`} />

      {!isModal && active && autoRenew && HAS_PAYMENT && (
        <button
          type="button"
          onClick={manageSubscription}
          className={S.ctaBtn}
          data-testid="BTN_ACME_MANAGE_SUB"
        >Manage Subscription</button>
      )}

      {active && autoRenew && !HAS_PAYMENT && (
        <button
          type="button"
          onClick={updatePaymentModal}
          className={S.ctaBtn}
          data-testid="BTN_ACME_ADD_BILLING"
        >Add Billing</button>
      )}

      {active && !autoRenew && (
        <>
          <button
            type="button"
            onClick={HAS_PAYMENT ? handleArOn : updatePaymentModal}
            className={S.ctaBtn}
            data-testid="BTN_ACME_RESTART_SUB"
          >Resume Subscription</button>
          <Legal className={S.legal}/>
        </>
      )}

      <img
        src={IS_HAPPY_STAR ? HAPPY_STAR : MEH_STAR}
        alt={IS_HAPPY_STAR ? 'Happy Star' : 'Sad Star'}
        className={S.star}
      />
    </>
  );
};

SimpActive.displayName = 'SimpActive';
SimpActive.propTypes   = {
  isModal:          PropTypes.bool.isRequired,
  isInTrialPeriod:  PropTypes.bool.isRequired,
  active:           PropTypes.bool.isRequired,
  sku:              PropTypes.string.isRequired,
  autoRenew:        PropTypes.bool.isRequired,
  expireOn:         PropTypes.string.isRequired,
  renewalSku:       PropTypes.string.isRequired,
  renewalFrequency: PropTypes.string.isRequired,
  isCancelled:      PropTypes.bool.isRequired
};

export { SimpActive };
